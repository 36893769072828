import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ko"; // 한국어 언어 팩
import router from "@/router";
import VueApexCharts from "vue-apexcharts";
import { Bar, Line } from "vue-chartjs";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import vueCookies from "vue-cookies";
import axios from "axios";
import VueQrcodeReader from "vue-qrcode-reader";
import { Buffer } from "buffer";
import process from "process";
import VCalendar from 'v-calendar';
import 'firebase/compat/messaging'
import vClickOutside from 'vue-click-outside';
import VueI18n from 'vue-i18n';
import ko from './i18n/ko.json';
import cn from './i18n/cn.json';
import ko_terms from './i18n/ko_terms.json';
import cn_terms from './i18n/cn_terms.json';
import axiosURL, { setBaseURL } from './service/axiosUrl';

Vue.use(VueI18n);

const mergedKoMessages = {
  ...ko,
  ...ko_terms
};

const mergedCnMessages = {
  ...cn,
  ...cn_terms
};

const i18n = new VueI18n({
  locale: 'ko', // 기본 언어
  messages: {
    'ko': mergedKoMessages,
    'cn': mergedCnMessages,
  }
});

// 한국어 css
import("./assets/css/kr/default.css");
import("./assets/css/kr/hyh.css");
import("./assets/css/kr/yymim.css");
import("./assets/css/kr/jinmyeong.css");


// 중국어 css
// import("./assets/css/cn/default.css");
// import("./assets/css/cn/hyh.css");
// import("./assets/css/cn/yymim.css");
// import("./assets/css/cn/jinmyeong.css");


window.process = process;
window.Buffer = Buffer;

document.addEventListener("contextmenu", e => {
  e.target.matches("img") && e.preventDefault()
})



window.Kakao.init("ec213f32ca1b03805f693b468a79f75e");

Vue.config.productionTip = false;

Chart.register(CategoryScale);

Vue.component("bar-chart", Bar);
Vue.component("line-chart", Line);
Vue.use(VueQrcodeReader);



Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;

Vue.use(VueApexCharts);

Vue.use(VCalendar);

Vue.directive('click-outside', vClickOutside);


Vue.component("apexchart", VueApexCharts);
Vue.use(vueCookies);

Vue.prototype.$meta = {};

// Axios를 Vue 인스턴스에 글로벌로 설정
Vue.prototype.$axios = axios.create({
  headers: {
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': true,
  },
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: process.env.VUE_APP_BASE_URL,
});
Vue.prototype.$axiosUrl = axiosURL;
Vue.prototype.$setBaseURL = setBaseURL;



Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (
      to.name !== "LoginIndex" &&
      to.name !== "Register" &&
      to.name !== "Register2" &&
      to.name !== "Register3" &&
      to.name !== "FindID" &&
      to.name !== "FindPW" &&
      to.name !== "FAQ" &&
      to.name !== "WalkShare" &&
      to.name !== undefined
    ) {
      const expireDate = new Date(
        decodeURIComponent(Vue.$cookies.get("expire_date"))
      );
      const currentDate = new Date(); // 현재 시간
      const expireDateTime = new Date(expireDate); // 세션 만료 시간

      if (currentDate > expireDateTime) {
        this.$cookies.keys().forEach(cookie => {
          if (cookie !== 'server-name' && cookie !== 'mqtt-server') {
            this.$cookies.remove(cookie);
          }
        });

        next((vm) => {
          vm.$router.push({ name: "LoginIndex" });
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),

  el: "#app",
  components: {
    apexchart: VueApexCharts,
  },
  data: {
    series: [44, 55, 41, 17, 15],
    chartOptions: {
      chart: {
        width: 380,
        type: "donut",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  },
  mounted() {
    this.setVh();
    window.addEventListener("resize", this.setVh);
  },
  methods: {
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    logout() {
      const expireDate = new Date(
        decodeURIComponent(Vue.$cookies.get("expire_date"))
      );
      const currentDate = new Date(); // 현재 시간
      const expireDateTime = new Date(expireDate); // 세션 만료 시간

      if (currentDate > expireDateTime) {
        this.$cookies.keys().forEach(cookie => {
          if (cookie !== 'server-name' && cookie !== 'mqtt-server') {
            this.$cookies.remove(cookie);
          }
        });

        Vue.$router.push({ name: "LoginIndex" });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setVh);
  },
}).$mount("#app");
